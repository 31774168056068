(function($){
	$(document).ready(function() {
		$('.banner').navOffcanvas();
		//$('.footer-site').navPageAnchors();
		$('.nav-section-links').navPageAnchors();
		$('.main').imageModal();
		$('.main').videoModal();
		
		/*
			$('.banner').ajaxHelper({
				wpAction: 'bcd_load_page'
			});
		*/
		//$('.acf-map').mapController();

		//$('.widget-expander').widgetExpander();

		$('#nav-taxonomy').ajaxForm({
			$content: $('.the-loop')
		});
	});    	
})(jQuery);
/*
 *	Breadcrumb Digital Bootstrap Plugin
 *  Custom expander
 *	- enables dropdowns on any element
 *	- requires molecules/expanders.scss
 * 	- see example: templates/molecules/expander.php
 */

(function ($) {
	'use strict';

	var WidgetExpander = function(element, options) {
		
		//options
		this.options = $.extend({}, WidgetExpander.DEFAULTS, options);
		this.toggleClass = this.options.toggleClass;

		this.$element = $(element);		
		this.$toggle = $(element).find(this.toggleClass);

		this.init();		
	};

	WidgetExpander.DEFAULTS = {
		toggleClass: '.expander-toggle'
	};
	
	WidgetExpander.prototype.init = function() {

		var self = this;

		self.$toggle.click(function(event) {
			if (event.preventDefault) {
				event.preventDefault();
			}
			else {
				event.returnValue = false;
			}

			self.$element.toggleClass('expand');
			
		});
	};


	// PLUGIN DEFINITION
	// ==========================

	function Plugin(option) {

		return this.each(function () {
		  var $this = $(this);
		  var data  = $this.data('bc.widgetExpander');
		  var options = $.extend({}, WidgetExpander.DEFAULTS, $this.data(), typeof option === 'object' && option);

		  if (!data) {
		  	$this.data('bc.widgetExpander', (data = new WidgetExpander(this, options)));
		  }
		  if (typeof option === 'string') {
		  	data[option].call($this);
		  }
		});
	}

	var old = $.fn.widgetExpander;

	$.fn.widgetExpander             = Plugin;
	$.fn.widgetExpander.Constructor = WidgetExpander;


	// DROPDOWN NO CONFLICT
	// ====================

	$.fn.dropdown.noConflict = function () {
		$.fn.widgetExpander = old;
		return this;
	};
})(jQuery);


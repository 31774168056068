(function($) {
	$(document).ready(function() {
		$('.slider-banner-image').bxSlider({ 
				controls: true,
				pager: false,
				auto: false,
				prevText: '<i class="icon-chevron-left"></i>',
				nextText: '<i class="icon-chevron-right"></i>',
				// nextSelector: '.slider-next',
  				// prevSelector: '.slider-prev',
				minSlides: 1,
	    		maxSlides: 1,
	    		slideMargin: 0,
	    		captions: false
  			});
	});
})(jQuery);
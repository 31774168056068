/*
 *	Breadcrumb Digital Bootstrap Plugin
 *
 *	Slider with modal component. Requires:
 *	- ACF Gallery field
 *	- bxslider-4 js plugin 
 *	- Custom scss /components/_slider-modal.scss
 */
(function ($) {
	'use strict';

	var SliderModal = function(element, options) {

		this.$element = $(element);
		this.isMobile = false;
		this.mobileBreakpoint = 767;
		this.imgSlider = null;

	    this.$sliderEl = this.$element.find('.img-slider');
	    this.$slides = this.$sliderEl.find('li');
	    this.$sliderThumbs = this.$element.find('.slider-thumbs');
	    this.$modal = this.$element.find('.full-img-modal');
	    this.timeout = null; // for resize debounce
	    this.init();

	    // Options
		this.options = $.extend({}, SliderModal.DEFAULTS, options);

		// Resize listener
		var self = this;

		window.addEventListener('resize', function() {
			self.resize();
		});
	};

	SliderModal.DEFAULTS = {};
	
	SliderModal.prototype.init = function() {

		if (window.innerWidth <= this.mobileBreakpoint) {
	    	this.isMobile = true;
	    	this.initImages();
	    }
	    else {
	    	this.isMobile = false;
	    	this.initBxSlider();
	    }
	};


	SliderModal.prototype.resize = function() {

		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		
		var self = this;

		self.timeout = setTimeout(function() {

			if (window.innerWidth <= self.mobileBreakpoint) {
		    	self.isMobile = true;
		    	self.initImages();
		    }
		    else {
		    	self.isMobile = false;
		    	self.initBxSlider();
		    }
		   
		}, 500);
	};

	SliderModal.prototype.initSlides = function() {

		var self = this;

		self.$slides.each(function() {
			var url = $(this).attr('data-img-url');
			$(this).attr('data-toggle', 'modal');
			$(this).find('.slide-img').css('background-image', 'url("' + url + '")');
		});
	};

	SliderModal.prototype.initBxSlider = function() {

		if (this.$sliderEl.length) {

			this.initSlides();

			this.imgSlider = this.$sliderEl.bxSlider({ 
				pager:  this.$sliderThumbs.length ? true : false,
				controls: this.$sliderThumbs.length ? false : true,
				auto: false,
				minSlides: 1,
	    		maxSlides: 1,
	    		slideMargin: 0,
	    		captions: false,
	    		pagerCustom: this.$sliderThumbs.length ? this.$sliderThumbs: ''
  			});

			var self = this;

  			self.$modal.on('show.bs.modal', function (event) {
				var slide = $(event.relatedTarget),
					imgFull = slide.data('img-url-full'),
					modal = $(this);

				modal.find('.modal-responsive-img').css('background-image', 'url("' + imgFull + '")');
			});
		}
	};

	SliderModal.prototype.initImages = function() {

		var self = this;

		if (self.imgSlider) {
			self.imgSlider.destroySlider();
		}	

		$(self.$slides).each(function() {
			var url = $(this).attr('data-img-url-sm');
			$(this).attr('data-toggle', '');
			$(this).find('img').attr('src', url);
		});
	};


	// PANEL GRID PLUGIN DEFINITION
	// ==========================

	function Plugin(option) {

		return this.each(function () {
		  var $this = $(this);
		  var data  = $this.data('bc.sliderModal');
		  var options = $.extend({}, SliderModal.DEFAULTS, $this.data(), typeof option === 'object' && option);

		  if (!data) {
		  	$this.data('bc.sliderModal', (data = new SliderModal(this, options)));
		  }
		  if (typeof option === 'string') {
		  	data[option].call($this);
		  }
		});
	}

	var old = $.fn.sliderModal;

	$.fn.sliderModal             = Plugin;
	$.fn.sliderModal.Constructor = SliderModal;


	// DROPDOWN NO CONFLICT
	// ====================

	$.fn.dropdown.noConflict = function () {
		$.fn.sliderModal = old;
		return this;
	};
})(jQuery);
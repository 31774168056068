/*
 *	Breadcrumb Digital Bootstrap Plugin
 *  Offcanvas navigation
 *	- retains native scroll when open
 *  - remembers last scroll position when closed
 *  - requires _nav-pattern-offcanvas.scss	
 */

(function ($) {
	'use strict';

	var NavOffcanvas = function(element, options) {
		this.$element = $(element);	
		this.$toggle = this.$element.find('.js-nav-offcanvas-toggle');
		this.$navOffcanvas = this.$element.find('.js-nav-offcanvas');
	
		this.isOpen = false;
	   	this.currScrollTop = 0;

		//options
		this.options = $.extend({}, NavOffcanvas.DEFAULTS, options);
		this.$content = this.options.$content;
		this.$footer = this.options.$footer;
		this.init();		
	};

	NavOffcanvas.DEFAULTS = {
		$content: $('.page-container'),
		$footer: $('.footer-site'),
		breakpoint: 768
	};

	NavOffcanvas.prototype.init = function() {

		var self = this;

		self.$toggle.click(function(event) {			
	    	if (!self.isOpen) {
		    	self.currScrollTop = $(window).scrollTop(); // stash original scroll position
		    	self.$navOffcanvas.addClass('open');

		    	self.$navOffcanvas.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(event) {
				    self.$content.addClass('hide-offcanvas');
				    self.$footer.addClass('hide-offcanvas');
					$(window).scrollTop(0);
					//self.$navOffcanvas.css('position', 'absolute'); // allows nav to scroll in window
					self.$navOffcanvas.addClass('opened');
					self.isOpen = true;

					// removes listener once done
					self.$navOffcanvas.off('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend');
				});
		    }
		    else {
		    	self.$content.removeClass('hide-offcanvas');
				self.$footer.removeClass('hide-offcanvas');

				$(window).scrollTop(self.currScrollTop); // return window to original scroll position
				
				self.$navOffcanvas.removeClass('open');
				//self.$navOffcanvas.css('position', 'fixed'); // return nav to its original position
				self.$navOffcanvas.removeClass('opened');
				self.isOpen = false;
		    }
	    });

		// window.addEventListener('resize', function() {
		// 	self.disable();
		// });
	};

	NavOffcanvas.prototype.resize = function() {
	};

	NavOffcanvas.prototype.disable = function() {
		if (window.innerWidth > this.options.breakpoint) {
			this.$content.removeClass('hide-offcanvas');
			this.$footer.removeClass('hide-offcanvas');
			this.$navOffcanvas.removeClass('open');
			this.$navOffcanvas.attr('style', ''); // return nav to its original position
			this.isOpen = false;

			$(window).scrollTop(this.currScrollTop); // return window to original scroll position
		}
		else {
			this.$navOffcanvas.attr('style', '');
		}
	};

	// PLUGIN DEFINITION
	// ==========================

	function Plugin(option) {

		return this.each(function () {
		  var $this = $(this);
		  var data  = $this.data('bc.navOffcanvas');
		  var options = $.extend({}, NavOffcanvas.DEFAULTS, $this.data(), typeof option === 'object' && option);

		  if (!data) {
		  	$this.data('bc.navOffcanvas', (data = new NavOffcanvas(this, options)));
		  }
		  if (typeof option === 'string') {
		  	data[option].call($this);
		  }
		});
	}

	var old = $.fn.navOffcanvas;

	$.fn.navOffcanvas             = Plugin;
	$.fn.navOffcanvas.Constructor = NavOffcanvas;


	// DROPDOWN NO CONFLICT
	// ====================

	$.fn.dropdown.noConflict = function () {
		$.fn.navOffcanvas = old;
		return this;
	};
})(jQuery);


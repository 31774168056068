/*
 *	Breadcrumb Digital Bootstrap Plugin
 *  Video Modal
 *  -Creates clickable thumbnails that open a popup with its respective embedded video
 */

(function ($) {
	'use strict';

	var VideoModal = function(element, options) {
	    // Options
		this.options = $.extend({}, VideoModal.DEFAULTS, options);

		this.$element = $(element);
		this.$thumbs = this.$element.find(this.options.clickSelector);
	    this.$modal = $(this.options.modalId);

	    this.init();
	};

	VideoModal.DEFAULTS = {
		clickSelector: '.js-thumbnail-video',
		modalId: '#primary-modal'
	};
	
	VideoModal.prototype.init = function() {

		var self = this;
		console.log('init video');

		self.$thumbs.attr('data-toggle', 'modal');
		self.$thumbs.attr('data-target', self.options.modalId);

		// Remove video HTML so that it won't play in the background when modal is hidden
		self.$modal.on('hide.bs.modal', function() {
			self.$modal.find('.modal-body').html('');
		});

		self.$thumbs.click(function(event) {
			if (event.preventDefault) {
				event.preventDefault();
			}
			else {
				event.returnValue = false;
			}

			self.$modal.on('show.bs.modal', function (event) {
				var $thumb = $(event.relatedTarget), 
					videoTitle =  $thumb.data('video-title'),
					embedCode = $thumb.data('embed-code');

				if( embedCode !== '') {
					self.$modal = $(this);

					if (videoTitle !== '') {
						self.$modal.find('.modal-title').html(videoTitle);
					}

					self.$modal.find('.modal-body').html('<div class="embed-responsive embed-responsive-16by9">' + embedCode + '</div>');
				}
			});
		});
	};


	// PANEL GRID PLUGIN DEFINITION
	// ==========================

	function Plugin(option) {

		return this.each(function () {
		  var $this = $(this);
		  var data  = $this.data('bc.videoModal');
		  var options = $.extend({}, VideoModal.DEFAULTS, $this.data(), typeof option === 'object' && option);

		  if (!data) {
		  	$this.data('bc.videoModal', (data = new VideoModal(this, options)));
		  }
		  if (typeof option === 'string') {
		  	data[option].call($this);
		  }
		});
	}

	var old = $.fn.videoModal;

	$.fn.videoModal             = Plugin;
	$.fn.videoModal.Constructor = VideoModal;


	// DROPDOWN NO CONFLICT
	// ====================

	$.fn.dropdown.noConflict = function () {
		$.fn.videoModal = old;
		return this;
	};
})(jQuery);
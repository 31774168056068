/*
 *	Breadcrumb Digital Bootstrap Plugin
 *  Makes page anchor links scroll
 *	- useful for side navigation and back to top links
 *	- can be used with just about any anchor link
 */

(function ($) {
	'use strict';

	var NavPageAnchors = function(element, options) {
		this.$element = $(element);		
		this.$links = this.$element.find('a').not('.external');

		//options
		this.options = $.extend({}, NavPageAnchors.DEFAULTS, options);
		this.offset = this.options.offset;
		this.offsetXs = this.options.offsetXs; 
		this.scrollTime = this.options.scrollTime;
		this.ftScroller = this.options.ftScroller;
		this.init();		
	};

	NavPageAnchors.DEFAULTS = {
		offset: 0,
		offsetXs: 0,
		scrollTime: 500,
		ftScroller: null
	};
	
	NavPageAnchors.prototype.init = function() {

		var self = this;

		this.$links.click(function(event) {
			if (event.preventDefault) {
				event.preventDefault();
			}
			else {
				event.returnValue = false;
			}

			var that = this,
				offset = (window.innerWidth > 480) ? self.offset : self.offsetXs;

			if(!self.ftScroller) {
				$('html, body').animate({
					scrollTop: ($(that.getAttribute('href')).offset().top - offset)
				}, self.scrollTime);
			}
			else {
				self.ftScroller.scrollTo(0, ($(that.getAttribute('href')).offset().top - offset), self.scrollTime);
			}
		});
	};


	// PLUGIN DEFINITION
	// ==========================

	function Plugin(option) {

		return this.each(function () {
		  var $this = $(this);
		  var data  = $this.data('bc.navPageAnchors');
		  var options = $.extend({}, NavPageAnchors.DEFAULTS, $this.data(), typeof option === 'object' && option);

		  if (!data) {
		  	$this.data('bc.navPageAnchors', (data = new NavPageAnchors(this, options)));
		  }
		  if (typeof option === 'string') {
		  	data[option].call($this);
		  }
		});
	}

	var old = $.fn.navPageAnchors;

	$.fn.navPageAnchors             = Plugin;
	$.fn.navPageAnchors.Constructor = NavPageAnchors;


	// DROPDOWN NO CONFLICT
	// ====================

	$.fn.dropdown.noConflict = function () {
		$.fn.navPageAnchors = old;
		return this;
	};
})(jQuery);


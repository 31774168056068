/*
 *	Breadcrumb Digital Bootstrap Plugin
 *  Makes a site Ajax friendly 
 *	- All links on the site will use AJAX when this plugin is enabled
 *	- Requires templates/molecules/ajax-header.php, it creates the site nonce, sets the homepage id and includes the loader html
 */

(function ($) {
	'use strict';

	var AjaxHelper = function(element, options) {
		this.$element = $(element);
		this.$links = $(element).find('a');
		
		//options
		this.options = $.extend({}, AjaxHelper.DEFAULTS, options);
		this.nonce =  this.options.nonce;
		this.homeId =  this.options.homeId;
		this.wpAction = this.options.wpAction;
		this.$content =  this.options.$content;
		this.onBeforeSend = this.options.onBeforeSend;
		this.onSuccess = this.options.onSuccess;
		this.init();

		var self = this;

		window.history.replaceState({id: this.homeId}, window.location);

		window.onpopstate = function(event) {
			var state = event.state;

			self.load(state.id, state.url, false);
		};
	};

	AjaxHelper.DEFAULTS = {
		nonce: $("#site-nonce").attr('value'),
		homeId: $("#site-home-id").attr('value'),
		wpAction: 'bcd_load_element',
		$content: $('main'),

		onBeforeSend: function() {
			$('.modal-loader').modal('show');
		},

		onSuccess: function() {
			$('.modal-loader').modal('hide');	
		},
	};
	
	AjaxHelper.prototype.init = function() {

		var self = this;

		this.$links = this.$element.find('a');

		// remove previously initialized click events
		self.$links.off();

		// check if link has valid page/post id
		self.$links.click(function(event) {
			if($(this).attr("data-id") > 0) {

				if (event.preventDefault) {
					event.preventDefault();
				}
				else {
					event.returnValue = false;
				}

				self.load($(this).attr("data-id"), $(this).attr("data-taxonomy"), $(this).attr("href"), true);
			}
		});
	};	

	AjaxHelper.prototype.load = function(id, taxonomy, url, pushState) {
		var self = this;

		$.ajax({
			type : "GET",
			dataType : "html",
			url : admin_ajax.url,
			data : {
				action: self.wpAction,
				nonce: self.nonce,
				id: id,
				taxonomy: taxonomy
			},
			beforeSend: function() {
				self.onBeforeSend();
			},
			success: function(response) {
				if(pushState) {
					window.history.pushState({id: id}, '', url);
				}
				self.$content.html(response);

				self.init();

				self.onSuccess();
			},
			error: function(er) {
				console.log('i am error');
			}
		});   
	};
	
	// PLUGIN DEFINITION
	// ==========================

	function Plugin(option) {

		return this.each(function () {
			var $this = $(this);
			var data  = $this.data('bc.ajaxHelper');
			var options = $.extend({}, AjaxHelper.DEFAULTS, $this.data(), typeof option === 'object' && option);

			if (!data) {
				$this.data('bc.ajaxHelper', (data = new AjaxHelper(this, options)));
			}
			if (typeof option === 'string') {
				data[option].call($this);
			}
		});
	}

	var old = $.fn.ajaxHelper;

	$.fn.ajaxHelper             = Plugin;
	$.fn.ajaxHelper.Constructor = AjaxHelper;


	// DROPDOWN NO CONFLICT
	// ====================

	$.fn.dropdown.noConflict = function () {
		$.fn.ajaxHelper = old;
		return this;
	};
})(jQuery);
(function($) {
	$(document).ready(function() {
		$('.slider-posts').bxSlider({ 
				controls: true,
				pager: true,
				auto: false,
				pagerType: 'short',
				pagerShortSeparator: 'of',
				pagerSelector: '.slider-pager',
				prevText: '<i class="icon-chevron-left"></i>',
				nextText: '<i class="icon-chevron-right"></i>',
				nextSelector: '.slider-next',
  				prevSelector: '.slider-prev',
				minSlides: 1,
	    		maxSlides: 1,
	    		slideMargin: 0,
	    		captions: false
  			});
	});
})(jQuery);
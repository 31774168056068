/*
 *	Breadcrumb Digital Bootstrap Plugin
 *  Image Modal
 *  -Creates clickable thumbnails that open a popup with its respective fullsized image
 */

(function ($) {
	'use strict';

	var ImageModal = function(element, options) {
	    // Options
		this.options = $.extend({}, ImageModal.DEFAULTS, options);

		this.$element = $(element);
		this.$thumbs = this.$element.find(this.options.clickSelector);
	    this.$modal = $(this.options.modalId);
	    this.init();
	};

	ImageModal.DEFAULTS = {
		clickSelector: '.js-thumbnail-img',
		modalId: '#primary-modal'
	};
	
	ImageModal.prototype.init = function() {

		console.log('init');
		var self = this;

		self.$thumbs.attr('data-toggle', 'modal');
		self.$thumbs.attr('data-target', '#primary-modal');

		// Remove image when modal is hidden
		self.$modal.on('hide.bs.modal', function() {
			self.$modal.find('.modal-body').html('');
		});

		self.$thumbs.click(function(event) {
			if (event.preventDefault) {
				event.preventDefault();
			}
			else {
				event.returnValue = false;
			}

			self.$modal.on('show.bs.modal', function(event) {

				var $thumb = $(event.relatedTarget), 
					imgFull = $thumb.data('img-url-full'),
					alt = $thumb.attr('alt') ? $thumb.attr('alt') : '';

				if ($thumb.data('img-url-full') !== '') {
					self.$modal = $(this);
					self.$modal.find('.modal-body').html('<img src="' + imgFull + '" class="img-responsive" alt="' + alt + '"/><div class="modal-text">' + alt + '</div>');
				}
			});
		});
	};


	// PANEL GRID PLUGIN DEFINITION
	// ==========================

	function Plugin(option) {

		return this.each(function () {
		  var $this = $(this);
		  var data  = $this.data('bc.imageModal');
		  var options = $.extend({}, ImageModal.DEFAULTS, $this.data(), typeof option === 'object' && option);

		  if (!data) {
		  	$this.data('bc.imageModal', (data = new ImageModal(this, options)));
		  }
		  if (typeof option === 'string') {
		  	data[option].call($this);
		  }
		});
	}

	var old = $.fn.imageModal;

	$.fn.imageModal             = Plugin;
	$.fn.imageModal.Constructor = ImageModal;


	// DROPDOWN NO CONFLICT
	// ====================

	$.fn.dropdown.noConflict = function () {
		$.fn.imageModal = old;
		return this;
	};
})(jQuery);
/*
 *	Breadcrumb Digital Bootstrap Plugin
 *  Custom Bootstrap style dropdown
 *	- supports multilevel dropdowns
 */

(function ($) {
	'use strict';

	var backdrop = '.dropdown-backdrop';
	var	toggle = '[data-toggle="bc-dropdown"]';

	var DropdownCustom = function() {
		$(element).on('click.bc.dropdown', this.toggle);
	};

	function getParent($this) {
		var selector = $this.attr('data-target');

	    if (!selector) {
	      	selector = $this.attr('href');
			selector = selector && /#[A-Za-z]/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, ''); // strip for ie7
	    }

	    var $parent = selector && $(selector);

	    return ($parent && $parent.length) ? $parent : $this.parent();
	}
	
	function clearMenus(e) {
		if (e && e.which === 3) {
			return;
		}

	    $(backdrop).remove();

	    $(toggle).each(function () {
			var $this         = $(this);
			var $parent       = getParent($this);
			var relatedTarget = { relatedTarget: this };

			if (!$parent.hasClass('open')) {
				return;
			}

			if (e && e.type === 'click' && /input|textarea/i.test(e.target.tagName) && $.contains($parent[0], e.target)) {
				return;	
			} 

			$parent.trigger(e = $.Event('hide.bc.dropdown', relatedTarget));

			if (e.isDefaultPrevented()) {
				return;
			}

			$this.attr('aria-expanded', 'false');
			$parent.removeClass('open').trigger('hidden.bc.dropdown', relatedTarget);
	    });
	}

	DropdownCustom.prototype.toggle = function(e) {
		console.log('~toggle~');

		var $this = $(this);

		if ($this.is('.disabled, :disabled')) { return; }

		var $parent  = getParent($this);
		var isActive = $parent.hasClass('open');
		var $grandparent = $parent.closest('.main-menu-item.open');

		if (!$grandparent.length) {
			clearMenus();
		}
		else {
			var $siblings = $parent.siblings();
			$siblings.removeClass('open');
			$siblings.find(toggle).attr('aria-expanded', 'false');
		}
	
		if (!isActive) {
			if ('ontouchstart' in document.documentElement && !$parent.closest('.navbar-nav').length) {
				// if mobile we use a backdrop because click events don't delegate
				$(document.createElement('div'))
				.addClass('dropdown-backdrop')
				.insertAfter($(this))
				.on('click', clearMenus);
			}

			var relatedTarget = { relatedTarget: this };
			
			$parent.trigger(e = $.Event('show.bc.dropdown', relatedTarget));

			if (e.isDefaultPrevented()) {
				return;
			}

			$this.trigger('focus').attr('aria-expanded', 'true');
			$parent.toggleClass('open').trigger('shown.bc.dropdown', relatedTarget);
		}
		else {
			console.log('already active');
			clearMenus();
		}

		return false;
	};


	// TO DO
	DropdownCustom.prototype.keydown = function(e) {
		if (!/(38|40|27|32)/.test(e.which) || /input|textarea/i.test(e.target.tagName)) {
			return;
		}

		var $this = $(this);

		e.preventDefault();
		e.stopPropagation();

		if ($this.is('.disabled, :disabled')) {
			return;
		}

		var $parent  = getParent($this);
		var isActive = $parent.hasClass('open');

		if (!isActive && e.which !== 27 || isActive && e.which === 27) {
			if (e.which === 27) {
				$parent.find(toggle).trigger('focus');
				return $this.trigger('click');
			}
		}

		var desc = ' li:not(.disabled):visible a';
		var $items = $parent.find('.nav-dropdown' + desc);


		if (!$items.length) {
			return;
		}

		var index = $items.index(e.target);

		if (e.which === 38 && index > 0) {
			index--;        // up
		}

		if (e.which === 40 && index < $items.length - 1) {
			index++;        // down
		}
		if (!index) {
			index = 0;
		}    
		// if (!~index) {
		// 	index = 0;
		// }                                   

		$items.eq(index).trigger('focus');
	};


	// PLUGIN DEFINITION
	// ==========================

	function Plugin(option) {

		return this.each(function () {
		  var $this = $(this);
		  var data  = $this.data('bc.dropdownCustom');

		  if (!data) {
		  	$this.data('bc.dropdownCustom', (data = new DropdownCustom(this)));
		  }
		  if (typeof option === 'string') {
		  	data[option].call($this);
		  }
		});
	}

	var old = $.fn.dropdownCustom;

	$.fn.dropdownCustom             = Plugin;
	$.fn.dropdownCustom.Constructor = DropdownCustom;


	// DROPDOWN NO CONFLICT
	// ====================

	$.fn.dropdown.noConflict = function () {
		$.fn.dropdownCustom = old;
		return this;
	};

	// APPLY TO STANDARD DROPDOWN ELEMENTS
  // ===================================

  $(document)
    .on('click.bs.dropdown.data-api', clearMenus)
    .on('click.bs.dropdown.data-api', '.dropdown form', function (e) { e.stopPropagation(); })
    .on('click.bs.dropdown.data-api', toggle, DropdownCustom.prototype.toggle)
    .on('keydown.bs.dropdown.data-api', toggle, DropdownCustom.prototype.keydown)
    .on('keydown.bs.dropdown.data-api', '.dropdown-menu', DropdownCustom.prototype.keydown);
})(jQuery);


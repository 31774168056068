/*
 *	Breadcrumb Digital Bootstrap Plugin
 *  Ajax enabled search form
 *	- requires templates/organisms/nav-taxonomy.php
 *	
 */
 (function ($) {
	'use strict';

	var AjaxForm = function(element, options) {
		this.$element = $(element);
		this.$fieldset = $(element).find('fieldset');


		

		//options
		this.options = $.extend({}, AjaxForm.DEFAULTS, options);
		this.nonce =  this.options.nonce;
		this.homeId =  this.options.homeId;

		var initialParam = (window.location.search).split('paged=');

		this.searchParam = initialParam[0];
		this.pageParam =  initialParam[1];

		this.wpAction = this.options.wpAction;
		this.$content =  this.options.$content;
		this.onBeforeSend = this.options.onBeforeSend;
		this.onSuccess = this.options.onSuccess;
		this.scrollOffset = this.options.scrollOffset;
		this.scrollOffsetXs = this.options.scrollOffsetXs;
		this.init();

		var self = this;

		this.data = {
				action: this.wpAction,
				nonce: this.nonce,
				post_type: $('input[name="post_type"]').val(),
				paged: 2
			};
		
	//	window.history.replaceState({data: this.data}, window.location);
		
		window.onpopstate = function(event) {
			var state = event.state;

			self.load(state.data);
		};
		
	};

	AjaxForm.DEFAULTS = {
		nonce: $("#site-nonce").attr('value'),
		homeId: $("#site-home-id").attr('value'),
		wpAction: 'bcd_load_by_taxonomy',
		$content: $('main'),
		scrollOffset: 0,
		scrollOffsetXs: 0,
		onBeforeSend: function() {
			$('.modal-loader').modal('show');
		},

		onSuccess: function() {
			
		}
	};
	
	AjaxForm.prototype.init = function() {
		var self = this;

 		self.$element.submit(function(event) {
			if (event.preventDefault) {
				event.preventDefault();
			}
		
			var taxQuery = [];

			self.searchParam = '?';

			/*To do tidy field type validation */
			self.$fieldset.each(function() {

				if ($(this).find('select[name="id"]').length && $(this).find('select[name="id"]').val() !== 'placeholder') {
					
					taxQuery.push({
						taxonomy: $(this).find('input[name="taxonomy"]').val(),
						field: 'slug',
						terms:[ $(this).find('select[name="id"]').val() ]
					});
					console.log('has select');
					self.searchParam +=  $(this).find('select[name="id"]').attr('data-taxonomy') + '=' + $(this).find('select[name="id"]').val() + '&';
					
				}

				if ($(this).find('input[type="radio"]:checked').length && $(this).find('input[type="radio"]:checked').val() !== 'placeholder') {
				
					taxQuery.push({
						taxonomy: $(this).find('input[name="taxonomy"]').val(),
						field: 'slug',
						terms:[ $(this).find('input[type="radio"]:checked').val() ]
					});

					self.searchParam +=  $(this).find('input[type="radio"]:checked').attr('data-taxonomy') + '=' + $(this).find('input[type="radio"]:checked').val() + '&';
				}
			});


			if ($(this).find('input[name="s"]').val() !== '') {
				self.searchParam += 's=' + $(this).find('input[name="s"]').val() + '&';
			}

			self.data = {
				action: self.wpAction,
				nonce: self.nonce,
				post_type: $(this).find('input[name="post_type"]').val(),
				s: $(this).find('input[name="s"]').val(), 
				tax_query: taxQuery
			};
			console.dir(self.data);
			self.load(self.data);
		});	

		self.paginate();
	};	

	AjaxForm.prototype.clear = function() {
		var self = this;
/*
		self.$fieldset.each(function() {

			if ($(this).find('select[name="id"]').val() !== 'placeholder') {
				$(this).attr('selected');
			}
		}*/
	};

	AjaxForm.prototype.paginate = function() {
		var self = this;

		self.$content.find('.page-link, .page-numbers').click(function(event) {
			if (event.preventDefault) {
				event.preventDefault();
			}
			//window.history.pushState({id: self.data }, '', $(this).attr("href"));

			//self.isPaging = true;
			self.data.url = $(this).attr("href");
			self.data.paged = $(this).attr("data-paged");

			if(self.data.paged > 1) {
				self.pageParam = 'paged=' + self.data.paged;
			}
			else {
				self.pageParam = '';
			}

			self.load(self.data);
			
		});
	};

	AjaxForm.prototype.load = function(data) {
		var self = this;

		$.ajax({
			type : "GET",
			dataType : "html",
			url : admin_ajax.url,
			data : self.data,
			beforeSend: function() {
				self.onBeforeSend();
			},
			success: function(response) {
				self.$content.html(response);

				$('.modal-loader').modal('hide');	

				var scrollOffset = (window.innerWidth > 480) ? self.scrollOffset : self.scrollOffsetXs;

				$('html, body').animate({
					scrollTop: self.$content.offset().top - scrollOffset
				}, 500);
				
				self.onSuccess();
				self.paginate();
				self.data = data;

				var newUrl = self.searchParam;

				if (self.data.paged) {
					if (self.searchParam === '') {
						self.searchParam = '?';
					}
					newUrl = self.searchParam + self.pageParam;
				}
				
				console.log('-data-');
				console.dir(data);

				window.history.pushState({ data: self.data }, '', newUrl);
			},
			error: function(er) {
				console.log('i am error');
			}
		});
	};
	/*
	AjaxForm.prototype.submit = function(id, postType, taxonomy, url, pushState) {
		var self = this;

		$.ajax({
			type : "GET",
			dataType : "html",
			url : admin_ajax.url,
			data : {
				action: self.wpAction,
				nonce: self.nonce,
				id: id,
				post_type: postType,
				taxonomy: taxonomy
			},
			beforeSend: function() {
				self.onBeforeSend();
			},
			success: function(response) {
				
				if(pushState) {
					window.history.pushState({id: id}, '', url);
				}
				
				self.$content.html(response);

				self.init();

				self.onSuccess();
			},
			error: function(er) {
				console.log('i am error');
			}
		});   
	};
	*/
	// PLUGIN DEFINITION
	// ==========================

	function Plugin(option) {

		return this.each(function () {
			var $this = $(this);
			var data  = $this.data('bc.ajaxForm');
			var options = $.extend({}, AjaxForm.DEFAULTS, $this.data(), typeof option === 'object' && option);

			if (!data) {
				$this.data('bc.ajaxForm', (data = new AjaxForm(this, options)));
			}
			if (typeof option === 'string') {
				data[option].call($this);
			}
		});
	}

	var old = $.fn.ajaxForm;

	$.fn.ajaxForm             = Plugin;
	$.fn.ajaxForm.Constructor = AjaxForm;


	// DROPDOWN NO CONFLICT
	// ====================

	$.fn.dropdown.noConflict = function () {
		$.fn.ajaxForm = old;
		return this;
	};
})(jQuery);